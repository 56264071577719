// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-jsx": () => import("/opt/build/repo/src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-blog-jsx": () => import("/opt/build/repo/src/pages/blog.jsx" /* webpackChunkName: "component---src-pages-blog-jsx" */),
  "component---src-pages-index-jsx": () => import("/opt/build/repo/src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-kdo-jsem-jsx": () => import("/opt/build/repo/src/pages/kdo-jsem.jsx" /* webpackChunkName: "component---src-pages-kdo-jsem-jsx" */),
  "component---src-pages-kontakty-jsx": () => import("/opt/build/repo/src/pages/kontakty.jsx" /* webpackChunkName: "component---src-pages-kontakty-jsx" */),
  "component---src-pages-me-advokatni-sluzby-jsx": () => import("/opt/build/repo/src/pages/me-advokatni-sluzby.jsx" /* webpackChunkName: "component---src-pages-me-advokatni-sluzby-jsx" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

